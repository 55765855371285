/* eslint no-console: 0 */

import { createApp } from 'vue'
import AppPieChart from '../pie-chart/app.vue'
import AppPieChartLegend from '../pie-chart/app-legend.vue'

document.addEventListener('turbolinks:load', () => {
  createApp(AppPieChart).mount("#dashboard-chart");
  createApp(AppPieChartLegend).mount("#dashboard-chart-legend");
})
