<script>
import PieChartLegend from './legend'

export default {
  el: '#dashboard-chart-legend',
  components: {
    PieChartLegend,
  }
}
</script>
