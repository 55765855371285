<template>
  <div class="chart-container">
    <svg :width="diameter" :height="diameter">
      <g :transform="transform">
        <path
          v-for="slice in slices"
          :fill="fillColor(slice.data.name)"
          :d="arc(slice)">
        </path>
      </g>
    </svg>
  </div>
</template>

<script>
import { computed, onMounted, ref, reactive } from 'vue';
import * as d3 from 'd3';
import ColorScheme from './color-scheme'

export default {
  name: 'pie-chart',
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => ColorScheme.colors()
    },
    radius: {
      type: Number,
      default: 75
    },
    thickness: {
      type: Number,
      default: 15
    }
  },
  setup(props) {
    const state = reactive({
      fetchColor: null,
    });

    onMounted(() => {
      state.fetchColor = d3.scaleOrdinal()
        .domain(props.categories.map(cat => cat.name)) 
        .range(props.colors);
    });

    const diameter = computed(() => props.radius * 2);

    const transform = computed(() => `translate(${props.radius},${props.radius})`);

    const slices = computed(() => {
      const computeSlices = d3.pie()
        .value(item => item.amount)
        .sort(null);
      
      return computeSlices(props.categories);
    });

    function fillColor(key) {
      return state.fetchColor == null ? "" : state.fetchColor(key);
    }

    function arc({ startAngle, endAngle }) {
      const computeArc = d3.arc()
        .innerRadius(props.radius - props.thickness)
        .outerRadius(props.radius);

      return computeArc({ startAngle, endAngle });
    }
    return {
      state,
      diameter,
      transform,
      slices,
      fillColor,
      arc,
    }
  }
}
</script>

<style scoped type="scss">
</style>
