<script>
import PieChart from './chart'

export default {
  el: '#dashboard-chart',
  components: {
    PieChart
  }
}
</script>
